<template>
    <div ref="dropdownContainer" class="font-select form-group pb-2" :class="dropdownContainerClasses">
        <div v-if="options" class="d-flex justify-content-between">
            <label>
                {{ label }}
                <PremiumMarker v-if="checkPremium(fontFamily)" :name="'premium-font|' + id" feature="formatting">
                    <p>You've chosen a premium font!</p>
                </PremiumMarker>
            </label>
            <b-form-checkbox :id="'single-font-' + uuid" v-model="singleFont" value="1" unchecked-value="0">
                Apply to entire worksheet
            </b-form-checkbox>
        </div>

        <Multiselect
            ref="select"
            v-model="fontFamily"
            v-on-clickaway="close"
            :class="[$fonts.getFontClass(value)]"
            :options="fontList"
            :searchable="false"
            :show-labels="false"
            :disabled="disabled"
            :group-values="grouped ? 'list' : null"
            :group-label="grouped ? 'group' : null"
            :group-select="false"
            track-by="name"
            label="name"
            placeholder="Pick a value"
        >
            <template slot="option" slot-scope="props">
                <div v-if="props.option.$isLabel" class="w-100 text-body font-weight-bold text-center f-13">
                    <span class="text-nowrap mr-1">
                        {{ props.option.$groupLabel }}
                    </span>
                </div>
                <div v-else class="flex flex-nowrap pointer-events-none f-13" :class="props.option.class">
                    <span class="text-nowrap mr-1">{{ props.option.alias }}</span>
                    <PremiumMarker v-if="props.option.isPremium" :popover="false" feature="formatting"></PremiumMarker>
                </div>
            </template>

            <template slot="singleLabel" slot-scope="props">
                <div class="flex flex-nowrap pointer-events-none f-13" :class="props.option.class">
                    <span class="text-nowrap mr-1">{{ props.option.alias }}</span>
                    <PremiumMarker v-if="props.option.isPremium" :popover="false" feature="formatting"></PremiumMarker>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import uuidv1 from 'uuid/v1'
import { mixin as clickaway } from 'vue-clickaway'
import Multiselect from 'vue-multiselect'
import PremiumMarker from './premium-marker.vue'
import EditPayWall from '../mixins/EditPayWall'
import { STANDARD_PRINT, STANDARD_CURSIVE, DNEALIAN_PRINT } from '../store/helpers/documentHelpers'

export default defineComponent({
    name: 'FontSelect',
    components: {
        PremiumMarker,
        Multiselect,
    },
    mixins: [EditPayWall, clickaway],
    props: {
        value: {
            type: String,
            default: '',
        },
        field: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
        },
        options: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'sm',
        },
        font: {
            type: String,
            default: '',
        },
        // TODO: See if this can be removed
        fonts: {
            type: Array,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        widget: {
            type: String,
            default: '',
        },
        grouped: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uuid: uuidv1(),
            selected: '',
            initialBodyFont: '',
        }
    },
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            entity: 'document/documentEntity',
            entity_type: 'document/entityType',
            premiumFonts: 'document/getPremiumFonts',
            documentFonts: 'document/documentFonts',
            addWidgetBackgroundColor: 'abtests/addWidgetBackgroundColor',
        }),
        singleFont: {
            get() {
                return this.document.single_font
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    single_font: Number(value),
                })
                this.setAllFonts(this.fontFamily)
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        fontFamily: {
            get() {
                if (this.entity_type !== 'worksheet' && this.widget === 'instruction') {
                    return this.selectedFont(this.documentFonts['instructions_font'])
                }

                if (this.value) {
                    return this.selectedFont(this.selected)
                }

                if (this.font) {
                    return this.selectedFont(this.font)
                }

                return this.selectedFont(this.documentFonts[this.field])
            },
            set(fontFamily) {
                if (fontFamily) {
                    fontFamily = fontFamily.name
                    if (this.font) {
                        this.selected = fontFamily
                        this.$emit('changed', { font: this.selected })
                        return
                    }
                    if (!this.document.single_font) {
                        this.$emit('changed', { font: fontFamily })
                        this.$store.dispatch('document/setDocumentFont', {
                            field: this.field,
                            font: fontFamily,
                        })
                        this.$store.dispatch('document/storeDocumentState')
                    } else {
                        this.setAllFonts(fontFamily)
                    }
                }
            },
        },
        fontOptions() {
            if (this.grouped) return []

            let options = []
            let fonts = this.fonts ? this.fonts : this.$fonts.fonts
            fonts.forEach((font) => {
                let option = {
                    alias: this.getAlias(font),
                    name: font,
                    class: this.$fonts.getFontClass(font),
                    isPremium: this.checkPremium(font),
                    $isDisabled: this.checkPremium(font) && this.document.is_published && font !== this.initialBodyFont,
                }
                options.push(option)
            })

            return options
        },
        groupedFontOptions() {
            if (!this.grouped) return []
            let fonts = this.fonts ? this.fonts : this.$fonts.fonts
            return fonts.map((font) => {
                let list = font.list.map((font) => {
                    let option = {
                        alias: this.getAlias(font),
                        name: font,
                        class: this.$fonts.getFontClass(font),
                        isPremium: this.checkPremium(font),
                        $isDisabled: this.checkPremium(font) && this.document.is_published && font !== this.initialBodyFont,
                    }
                    return option
                })
                return {
                    group: font.group,
                    list: list,
                }
            })
        },
        fontList() {
            return this.grouped ? this.groupedFontOptions : this.fontOptions
        },
        dropdownContainerClasses() {
            return {
                [`test-header-style-${this.addWidgetBackgroundColor}`]: !!this.addWidgetBackgroundColor,
                'f-14': this.size === 'sm',
            }
        },
    },
    mounted() {
        this.selected = this.value
        this.initialBodyFont = window.initial_body_font ?? ''
    },
    methods: {
        selectedFont(name) {
            if (!this.grouped) {
                return this.fontOptions.filter((font) => font.name === name)
            }

            let option
            this.groupedFontOptions.forEach((font) => {
                font.list.find((font) => {
                    if (font.name === name) option = font
                })
            })

            return option
        },
        close() {
            this.$refs.select.open = false
        },
        handleClickAway(event) {
            if (event.target == document.body) return
            if (event.target == this.$refs.dropdownContainer) return
            this.close()
        },
        input(val) {
            this.$emit('input', val.name)
        },
        setAllFonts(fontFamily) {
            //update all fonts
            let documentFonts = {
                title_font: fontFamily,
                student_info_font: fontFamily,
            }

            this.$store.dispatch('document/setDocument', documentFonts)
            this.$store.dispatch('document/setInstructionStyle', {
                font: fontFamily,
            })

            if (this.entity.type !== 'handwriting') {
                this.$store.dispatch('document/setDocumentStyle', {
                    font: fontFamily,
                })
            }
            this.$store.dispatch('document/setWordbankStyle', {
                font: fontFamily,
            })
            this.$store.dispatch('document/storeDocumentState')
        },
        checkPremium(font) {
            return this.premiumFonts.includes(font)
        },
        isStandardFont(font) {
            return [STANDARD_PRINT, STANDARD_CURSIVE].includes(font)
        },
        getAlias(font) {
            if (font === DNEALIAN_PRINT) {
                return "D'Nealian Print"
            }
            return this.isStandardFont(font) ? font.replace('Standard', 'Basic') : font
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import 'Scss/runtime.scss';

.font-select {
    .multiselect {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        max-height: 30px;
        min-height: initial;
        .multiselect__select {
            position: relative;
            min-height: initial;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            &:before {
                top: 0;
                color: #000;
                margin-top: 0px;
                border-color: #000 transparent transparent;
            }
        }
        .multiselect__tags {
            min-height: initial;
            flex-grow: 1;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
            .multiselect__single {
                height: 100%;
                min-height: initial;
                display: block;
                overflow: hidden;
                background: #fff;
                margin-bottom: 0px;
            }
        }

        .multiselect__option--disabled {
            background: #ededed !important;
            color: #a6a6a6 !important;
        }

        .multiselect__content-wrapper {
            border-radius: 5px;
            box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
            border: 1px solid #6c757d !important;
        }
    }
}

.multiselect__option--highlight {
    background: $primary !important;
}

.multiselect__option--selected {
    background: transparent !important;
}

.multiselect__option--selected.multiselect__option--highlight {
    background: $primary !important;
}

.font-select .multiselect--disabled .multiselect__tags .multiselect__single {
    height: 100%;
    min-height: initial;
    display: block;
    overflow: hidden;
    background: #f1f1f1;
    margin-bottom: 0px;
}

.f-14 {
    font-size: 14px;
}
</style>
